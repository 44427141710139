import { triggerClassOnScroll } from 'allink-core-static/js/modules/trigger-class-on-scroll';

$(() => {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Header

    IMPORTANT: If the 'header buffer' is enabled, the scroll distance breakpoints have to be the same height (AT LEAST), otherwise the buffer becomes visible.

    */

    function initHeader() {
        if (document.querySelector('.site-header.compact-mode-enabled')) {
            //const scrollDistance = document.querySelector('.site-header__container').offsetHeight;
            const scrollDistance = 1;

            triggerClassOnScroll({
                scroll: scrollDistance,
                class_to_trigger: 'compact-mode',
                element: document.querySelector('.site-header.compact-mode-enabled'),
            });
            triggerClassOnScroll({
                scroll: scrollDistance,
                class_to_trigger: 'header-compact-mode',
                element: document.querySelector('html'),
            });
        }
    }

    // init on page load
    initHeader();

    // custom event
    $(window).on('initHeader', () => {
        initHeader();
    });

    // initialize again when viewport width has changed
    $(window).on('viewportWidthHasChanged', () => {
        initHeader();
    });
});
